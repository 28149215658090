import * as React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { Header } from "../components/atoms";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default function AcceptableUse() {
  const { t } = useTranslation();
  return (
    <Layout title={t("Acceptable Use Policy")}>
      <div className="mt-16 max-w-6xl px-8 mx-auto">
        <Header>Acceptable Use Policy</Header>
        <div className="pt-8 text-chocolate text-justify">
          <h3 className="my-2 font-bold uppercase" id="introduction">
            1. Introduction
          </h3>
          <p>
            This Acceptable Use Policy (“AUP”) governs the use of Amplified’s
            artificial intelligence software as a service application (the
            “Service”) by our business customers and their authorized users.
            This AUP is incorporated by reference into and forms an integral
            part of:
          </p>
          <ul className="list-disc list-inside">
            <li>Amplified’s Terms of Use</li>
            <li>
              Any Software Services Agreement or Master Services Agreement
              (“MSA”)
            </li>
            <li>Any Order Forms executed between Amplified and customer</li>
          </ul>
          <p>
            By agreeing to our Terms of Use or executing an MSA or Order Form
            with Amplified, you agree to ensure compliance with this AUP by all
            of its authorized users who access the Service. For purposes of this
            AUP:
          </p>
          <ul className="list-disc list-inside">
            <li>
              “Customer” refers to the business entity that has entered into an
              agreement with Amplified
            </li>
            <li>
              “Authorized Users” refers to individuals who are authorized by the
              Customer to use the Service
            </li>
            <li>
              “you” or “your” refers to both the Customer and its Authorized
              Users
            </li>
          </ul>
          <p>
            The Customer is responsible for ensuring that all of its Authorized
            Users comply with this AUP and may be held liable for any violations
            by its Authorized Users.
          </p>
          <h3 className="my-2 font-bold uppercase" id="compliance-requirements">
            2. Compliance Requirements
          </h3>
          <h3 className="my-2 font-bold uppercase" id="general-compliance">
            2.1 General Compliance
          </h3>
          <ul className="list-disc list-inside">
            <li>
              You must comply with all applicable local, state, national, and
              international laws and regulations
            </li>
            <li>You must be at least 18 years of age to use the Service</li>
            <li>You must maintain accurate and complete account information</li>
            <li>
              You are responsible for maintaining the security of your account
              credentials
            </li>
          </ul>
          <h3 className="my-2 font-bold uppercase" id="international-usage">
            2.2 International Usage
          </h3>
          <ul className="list-disc list-inside">
            <li>
              While the Service is available internationally, you are
              responsible for ensuring compliance with local regulations in your
              jurisdiction
            </li>
            <li>
              You must comply with all applicable export control laws and
              regulations
            </li>
          </ul>
          <h3 className="my-2 font-bold uppercase" id="prohibited-uses">
            3. Prohibited Uses
          </h3>
          <p>
            You agree not to use the Service, nor permit, facilitate, or assist
            others to use the Service for:
          </p>
          <h3 className="my-2 font-bold uppercase" id="technical-restrictions">
            3.1 Technical Restrictions
          </h3>
          <ul className="list-disc list-inside">
            <li>
              Reverse engineering, decompiling, or disassembling the Service
            </li>
            <li>
              Attempting to discover the source code or underlying algorithms
            </li>
            <li>Creating derivative works based on the Service</li>
            <li>
              Circumventing any technical limitations or security measures
            </li>
            <li>
              Using automated systems, robots, or scrapers to access the Service
            </li>
            <li>Unauthorized bulk data access</li>
          </ul>
          <h3 className="my-2 font-bold uppercase" id="security-violations">
            3.2 Security Violations
          </h3>
          <ul className="list-disc list-inside">
            <li>Attempting to compromise system integrity or security</li>
            <li>Transmitting malware, viruses, or harmful code</li>
            <li>Interfering with or disrupting the Service or servers</li>
            <li>Imposing unreasonable loads on infrastructure</li>
            <li>Attempting unauthorized access to other users’ accounts</li>
          </ul>
          <h3 className="my-2 font-bold uppercase" id="content-restrictions">
            3.3 Content Restrictions
          </h3>
          <ul className="list-disc list-inside">
            <li>Generating or transmitting illegal content</li>
            <li>
              Creating or distributing hate speech, harassment, or
              discriminatory content
            </li>
            <li>Generating spam or fraudulent content</li>
            <li>Infringing on intellectual property rights</li>
            <li>
              Creating misleading AI-generated content without proper disclosure
            </li>
            <li>
              Collecting personally identifiable information without
              authorization
            </li>
          </ul>
          <h3 className="my-2 font-bold uppercase" id="commercial-restrictions">
            3.4 Commercial Restrictions
          </h3>
          <ul className="list-disc list-inside">
            <li>
              Using the Service for the benefit of a third party without
              authorization
            </li>
            <li>Operating a service bureau without authorization</li>
            <li>Building competitive products</li>
            <li>
              Using the Service for commercial solicitation purposes without
              authorization
            </li>
            <li>
              Reselling or redistributing the Service without authorization
            </li>
          </ul>
          <h3 className="my-2 font-bold uppercase" id="fair-use-requirements">
            4. Fair Use Requirements
          </h3>
          <h3 className="my-2 font-bold uppercase" id="resource-usage">
            4.1 Resource Usage
          </h3>
          <ul className="list-disc list-inside">
            <li>
              Usage must remain within established Parameters as defined in our{" "}
              <a href="https://support.amplified.ai/article/287-fair-use-policy">
                Fair Use Policy
              </a>
            </li>
            <li>Excessive usage may result in throttling</li>
          </ul>
          <h3
            className="my-2 font-bold uppercase"
            id="monitoring-and-enforcement"
          >
            4.2 Monitoring and Enforcement
          </h3>
          <ul className="list-disc list-inside">
            <li>Amplified monitors Service usage for compliance</li>
            <li>
              Violations may result in warnings, throttling, or service
              suspension
            </li>
            <li>Continued violations may result in account termination</li>
            <li>
              Amplified reserves the right to report illegal activities to
              authorities
            </li>
          </ul>
          <h3
            className="my-2 font-bold uppercase"
            id="data-and-content-guidelines"
          >
            5. Data and Content Guidelines
          </h3>
          <h3 className="my-2 font-bold uppercase" id="user-content">
            5.1 User Content
          </h3>
          <ul className="list-disc list-inside">
            <li>You retain ownership of your User Content</li>
            <li>
              You grant Amplified limited license to use your content strictly
              for sole purpose of providing the Services to you
            </li>
            <li>You must have necessary rights to submit content</li>
          </ul>
          <h3 className="my-2 font-bold uppercase" id="prohibited-content">
            5.2 Prohibited Content
          </h3>
          <p>Content that:</p>
          <ul className="list-disc list-inside">
            <li>Creates risk of harm or injury</li>
            <li>Exploits or harms minors</li>
            <li>Violates privacy or publicity rights</li>
            <li>Contains unlawful, harmful, or abusive material</li>
            <li>Violates third-party rights</li>
            <li>Contains false or misleading information</li>
          </ul>
          <h3 className="my-2 font-bold uppercase" id="service-limitations">
            6. Service Limitations
          </h3>
          <h3
            className="my-2 font-bold uppercase"
            id="data-access-and-download-restrictions"
          >
            6.1 Data Access and Download Restrictions
          </h3>
          <ul className="list-disc list-inside">
            <li>
              While the Service provides bulk data download capabilities, these
              capabilities may not be used to:
              <ul className="list-disc list-inside">
                <li>
                  Reproduce or attempt to reproduce a significant portion of the
                  public patent dataset
                </li>
                <li>Create a competing patent database or dataset</li>
                <li>
                  Systematically download, store, or aggregate patent data for
                  purposes beyond the intended use of the Service
                </li>
                <li>Redistribute or resell downloaded patent data</li>
              </ul>
            </li>
            <li>
              Bulk downloads must be:
              <ul className="list-disc list-inside">
                <li>
                  Performed through authorized API endpoints or user interface
                  features
                </li>
                <li>Used solely for Customer’s internal business purposes</li>
                <li>
                  Limited to reasonable volumes that do not impair Service
                  performance
                </li>
              </ul>
            </li>
            <li>
              Customers requiring extensive patent data access should contact 
              <a href="mailto:support@amplified.ai">support@amplified.ai</a> to
              discuss enterprise data licensing options
            </li>
            <li>
              Amplified reserves the right to:
              <ul className="list-disc list-inside">
                <li>Monitor download patterns and volumes</li>
                <li>
                  Implement technical measures to prevent excessive downloading
                </li>
                <li>
                  Throttle or suspend accounts showing download patterns
                  indicative of dataset reproduction attempts
                </li>
                <li>
                  Require written authorization for downloads exceeding certain
                  thresholds
                </li>
              </ul>
            </li>
          </ul>
          <h3 className="my-2 font-bold uppercase" id="changes-and-updates">
            7. Changes and Updates
          </h3>
          <p>
            Amplified reserves the right to modify this AUP at any time.
            Continued use of the Service after changes constitutes acceptance of
            the modified AUP. Material changes will be communicated through the
            Service or via email.
          </p>
          <h3
            className="my-2 font-bold uppercase"
            id="enforcement-and-penalties"
          >
            8. Enforcement and Penalties
          </h3>
          <h3 className="my-2 font-bold uppercase" id="violation-consequences">
            8.1 Violation Consequences
          </h3>
          <ul className="list-disc list-inside">
            <li>Warning and/or account lockout for initial violations</li>
            <li>Service throttling or limitation</li>
            <li>Account suspension or termination</li>
            <li>Legal action for serious violations</li>
          </ul>
          <h3 className="my-2 font-bold uppercase" id="reporting-violations">
            8.2 Reporting Violations
          </h3>
          <p>
            Report violations to{" "}
            <a href="mailto:support@amplified.ai">support@amplified.ai</a>
          </p>
          <h3 className="my-2 font-bold uppercase" id="contact-information">
            9. Contact Information
          </h3>
          <p>
            For questions about this AUP, contact:{" "}
            <a href="mailto:support@amplified.ai">support@amplified.ai</a>
          </p>
          <p>
            <em>Last modified on November 4, 2024.</em>
          </p>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
